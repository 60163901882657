// @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

// Colors.
$primary: #ff4f11;
$dark: #000000;
$light: #f5f5f5;
$muted: #6c757d;
$danger: #dc3545;
$success: #198754;
$purple: #9747ff;
$white: #ffffff;
$warning: #E0AF03;

body {
  -ms-overflow-syle: none;
  background-color: $white;
}

// Modals.
$modal-header-border-color: none;

// Fonts.
$font-family-base: 'Poppins';

// Formularios.
$form-check-input-bg: #ffffff;
$form-check-input-checked-bg-color: $dark;
$form-check-input-width: 2em;
$form-check-input-border-radius: 0.25em;
$form-check-radio-border-radius: 0;
$form-check-input-checked-border-color: $dark;
$input-placeholder-color: $dark;

$input-padding-y: 1em;
$input-padding-x: 1em;
$input-bg: $light;
$input-border-radius: 0.5em;
$input-border-width: 0;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;

$input-disabled-color: $muted;
$input-disabled-bg: $light;

// Buttons.
$btn-padding-y: 1em;
$btn-padding-x: 1em;
$btn-border-radius: 0.5em;

// Accordion.
$accordion-button-active-bg: white;
$accordion-button-color: black;
$accordion-button-active-color: black;
$accordion-button-padding-y: 0.5rem;
$accordion-button-padding-x: 0.5rem;
$accordion-padding-y: 0.5rem;
$accordion-padding-x: 0.5rem;
// $accordion-border-radius: 1rem;
// $accordion-inner-border-radius: 1rem;

// Queries
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;


@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';

.card {
  border-radius: 0.5em !important;
}

body {
  background-color: $light;
}

p {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.modal-content {
  border-radius: 0.5em;
  border: 0;
}
.Toastify__toast-container {
  @media (min-width: 480px) {
    width: 480px;
  }
}
.Toastify__toast {
  font-family: $font-family-base !important;
  border: 1px solid #fafafa;
  padding: 15px 30px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 2px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 1rem;
  font-weight: 600;
  @media (max-width: 480px) {
    border-radius: 0;
  }
  border-left: 3px solid $primary;
}
.Toastify__toast-icon {
  margin-top: 2px;
  align-self: baseline;
}
.pointer {
  cursor: pointer !important;
}

.zi-1055 {
  z-index: 1055 !important;
}

.qr-image {
  @media (max-width: 576px) {
    img {
      max-width: 100px;
    }
  }
}

.mobile-menu {
  @media (max-width: 768px) {
    position: fixed;
    z-index: 1040;
    border-radius: 0px !important;
    small {
      font-size: 10px;
    }
  }
}

.mobile-container {
  @media (max-width: 768px) {
    padding-bottom: 8rem !important;
  }
}

.mobile-actions {
  @media (max-width: 768px) {
    position: fixed;
    z-index: 1040;
    bottom: 82px;
    left: 8px;
    right: 8px;
    button {
      display: flex;
      flex-grow: 1;
    }
  }
  @media (max-width: 576px) {
    bottom: 74px;
  }
}

body,
html,
#root {
  height: 100% !important;
}

.navbar {
  min-height: 56px;
}

#offcanvasNavbar-expand-lg {
  width: 100% !important;
  height: 100% !important;
}

.navbar-toggler-icon {
  background-image: url('../assets/menu-sharp.svg');
  border-radius: $btn-border-radius;
  width: 24px;
  height: 24px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.fs-lg-6 {
  @media (min-width: 992px) {
    font-size: 1rem !important;
  }
}

.pt-6 {
  padding-top: 4rem;
}
.pt-lg-6 {
  @media (min-width: 992px) {
    padding-top: 4rem !important;
  }
}

.modal-body{
  overflow-x: auto;
  max-height: calc(100vh - 100px);
}

@include media-breakpoint-down(sm) {
  .mobile-modal {
    .modal-dialog {
      display: flex;
      flex-direction: column-reverse !important;
      margin: 0;
      width: 100vw;
      max-width: none;
      padding-right: 0 !important;
      padding-left: 0 !important;
      height: 100%;

      padding-top: 3.5rem !important;
      .modal-content {
        .modal-header,
        .modal-body,
        .modal-footer {
          background: white;
        }
        border: 0;
        padding: 0 !important;
        border-radius: 1rem 1rem 0 0;
        --bs-bg-opacity: 1;
        background-color: transparent !important;
        width: 100% !important;
        overflow-y: auto;
        .modal-footer {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
.object-fit-cover {
  object-fit: cover;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-check-input:checked {
  background-color: $success;
  border-color: $success;
}

.white-space-normal {
  white-space: normal;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.small-xs {
  font-size: 12px;
}

.badge-primary {
  color: $primary;
  background-color: rgba($color: $primary, $alpha: 0.1) !important;
}

.badge-success {
  color: $success;
  background-color: rgba($color: $success, $alpha: 0.1) !important;
}
.badge-danger {
  color: $danger;
  background-color: rgba($color: $danger, $alpha: 0.1) !important;
}

.badge-warning {
  color: $warning;
  background-color: rgba($color: $warning, $alpha: 0.1) !important;
}

.badge-muted {
  color: $muted;
  background-color: rgba($color: $muted, $alpha: 0.1) !important;
}

.badge-purple {
  color: $purple;
  background-color: rgba($color: $purple, $alpha: 0.1) !important;
}

hr {
  border: 1px solid $light;
}

.form-switch .form-check-input {
  height: 1.5em;
  width: 3em;
}
.check-small {
  .form-switch .form-check-input {
    height: 1em !important;
    width: 2em !important;
    margin: 0;
  }
  .form-check-label {
    font-size: 14px;
  }
}

.pac-container {
  z-index: 100000 !important;
}

.switch-mobile {
  @media (max-width: 575.98px) {
    .form-check-label {
      display: none;
    }
  }
}

.dropdown-item {
  &.active {
    background: $light;
    color: $dark;
  }
  &:hover {
    background-color: $light;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-animation {
  background: linear-gradient(110deg, #000000 8%, #1f1f1f 18%, #000000 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Responsive */
.resposive .react-colorful {
  width: auto;
}
.gear {
  animation-name: ckw;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text-muted {
  stroke: $muted;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url('../assets/check-white.svg');
  background-size: auto;
}

.form-switch .form-check-input:checked {
  background-image: url('../assets/radio-white.svg');
  background-size: contain;
}

.custom-accordion {
  .accordion-button:not(.collapsed) {
    box-shadow: none;
    padding: 0;
  }
  .accordion-button {
    padding: 0;
  }
}
.popup-image {
  background-image: url('../assets/popup-image-02.jpg');
  background-position: center;
  background-size: cover;
  min-width: 300px;
}

.rounded-4 {
  border-radius: 1rem !important;
}

.responsive-bg-light {
  background-color: $light;
}

@include media-breakpoint-up(sm) {
  .modal {
    overflow: hidden;
  }
  .modal-dialog-right {
    margin-right: initial;
    overflow: auto;
    margin-top: initial;
    height: 100%;
    max-width: 500px!important;
  }
  .modal-content-right {
    border-radius: 0;
    overflow: auto;
    height: 100%;
  }
}

@media (max-width: 768px) { /* Ajusta el valor según tus necesidades */
  .mobile-visible {
    display: table-cell;
  }
  .mobile-hidden {
    display: none;
  }
}

.pulse {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%
    }
    100% {
      background-position: -135% 0%
    }
  }
}

.custom-shadow {
  box-shadow: 0 8px 24px rgba(18,7,143,.04), 0 1px 2px rgba(14,9,72,.08), 0 0 0 1px rgba(14,9,72,.04), inset 0 0 3px rgba(19,39,108,.04), inset 0 -4px 1px rgba(19,39,109,.06);
}

@media (min-width: 575px) { 
  .modal-from-right.fade .modal-dialog{
    transform: translate(500px);
    transition:transform 0.2s ease-in-out;
  }  
  .modal-from-right.show .modal-dialog{
    transform: none;
  }
}

.cover-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.10) 100%);
}

.dropdown-no-arrow {
  .dropstart .dropdown-toggle::before {
    display: none;
  }
}

.custom-button {
  background-image: linear-gradient(rgba(214, 211, 209, 0.1), rgba(0,0,0,0));
  border-radius: 12px;
  border-color: rgb(229, 231, 235);
  padding: 4px;
  button {
    background-color: rgba(0,0, 0, 0);
    background-image: linear-gradient(rgb(255, 255, 255), rgba(231,229,228, 0.4));
    border-radius: 12px;
    border-color: rgb(229, 231, 235);
    border-image-width: 1px;
    border: 0;
    width: 100%;
    padding: 4px;
    box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.3) 0px 1px 3px 0px;
    div {
      background: white;
      border-radius: 8px;
      font-weight: 700;
      padding: 12px;
      border-color: rgb(229, 231, 235);
    }
  }
}

.kitchen-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
    @media (max-width: $xl) { 
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $lg) { 
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $sm) { 
      grid-template-columns: repeat(1, 1fr);
    }
  .card {
    min-width: 0;
  }
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
    animation-timing-function: ease-in-out;
  }
  50% {
    transform: translateY(-4px);
    animation-timing-function: ease-in-out;
  }
}

.pen-icon-animation {
  display: inline-block;
  animation: moveUpDown 1s infinite;
}

.tooltip-desktop-only {
  display: none;
}

@media (min-width: $md) {
  .tooltip-desktop-only {
    display: inline-block;
  }
}

.whatsapp-sticky {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9999;
}

